/**
 * Created by micaelalopez on 5/25/15.
 */

module.exports = (function (window, JSON) {
	/**
	 * MD5 Checksum calculation, generates a MD5Hash out of a string provided
	 * @param {string} str required, string to hash
	 * @return {string}
	 */
	function _stringToMD5(str) {
	  var hexcase = 0, chrsz = 8, cmc5 = function (x, e) {
		x[e >> 5] |= 0x80 << ((e) % 32);
		x[(((e + 64) >>> 9) << 4) + 14] = e;
		var a = 1732584193;
		var b = -271733879;
		var c = -1732584194;
		var d = 271733878;
		for (var i = 0; i < x.length; i += 16) {
		  var f = a;
		  var g = b;
		  var h = c;
		  var j = d;
		  a = md5_ff(a, b, c, d, x[i + 0], 7, -680876936);
		  d = md5_ff(d, a, b, c, x[i + 1], 12, -389564586);
		  c = md5_ff(c, d, a, b, x[i + 2], 17, 606105819);
		  b = md5_ff(b, c, d, a, x[i + 3], 22, -1044525330);
		  a = md5_ff(a, b, c, d, x[i + 4], 7, -176418897);
		  d = md5_ff(d, a, b, c, x[i + 5], 12, 1200080426);
		  c = md5_ff(c, d, a, b, x[i + 6], 17, -1473231341);
		  b = md5_ff(b, c, d, a, x[i + 7], 22, -45705983);
		  a = md5_ff(a, b, c, d, x[i + 8], 7, 1770035416);
		  d = md5_ff(d, a, b, c, x[i + 9], 12, -1958414417);
		  c = md5_ff(c, d, a, b, x[i + 10], 17, -42063);
		  b = md5_ff(b, c, d, a, x[i + 11], 22, -1990404162);
		  a = md5_ff(a, b, c, d, x[i + 12], 7, 1804603682);
		  d = md5_ff(d, a, b, c, x[i + 13], 12, -40341101);
		  c = md5_ff(c, d, a, b, x[i + 14], 17, -1502002290);
		  b = md5_ff(b, c, d, a, x[i + 15], 22, 1236535329);
		  a = md5_gg(a, b, c, d, x[i + 1], 5, -165796510);
		  d = md5_gg(d, a, b, c, x[i + 6], 9, -1069501632);
		  c = md5_gg(c, d, a, b, x[i + 11], 14, 643717713);
		  b = md5_gg(b, c, d, a, x[i + 0], 20, -373897302);
		  a = md5_gg(a, b, c, d, x[i + 5], 5, -701558691);
		  d = md5_gg(d, a, b, c, x[i + 10], 9, 38016083);
		  c = md5_gg(c, d, a, b, x[i + 15], 14, -660478335);
		  b = md5_gg(b, c, d, a, x[i + 4], 20, -405537848);
		  a = md5_gg(a, b, c, d, x[i + 9], 5, 568446438);
		  d = md5_gg(d, a, b, c, x[i + 14], 9, -1019803690);
		  c = md5_gg(c, d, a, b, x[i + 3], 14, -187363961);
		  b = md5_gg(b, c, d, a, x[i + 8], 20, 1163531501);
		  a = md5_gg(a, b, c, d, x[i + 13], 5, -1444681467);
		  d = md5_gg(d, a, b, c, x[i + 2], 9, -51403784);
		  c = md5_gg(c, d, a, b, x[i + 7], 14, 1735328473);
		  b = md5_gg(b, c, d, a, x[i + 12], 20, -1926607734);
		  a = md5_hh(a, b, c, d, x[i + 5], 4, -378558);
		  d = md5_hh(d, a, b, c, x[i + 8], 11, -2022574463);
		  c = md5_hh(c, d, a, b, x[i + 11], 16, 1839030562);
		  b = md5_hh(b, c, d, a, x[i + 14], 23, -35309556);
		  a = md5_hh(a, b, c, d, x[i + 1], 4, -1530992060);
		  d = md5_hh(d, a, b, c, x[i + 4], 11, 1272893353);
		  c = md5_hh(c, d, a, b, x[i + 7], 16, -155497632);
		  b = md5_hh(b, c, d, a, x[i + 10], 23, -1094730640);
		  a = md5_hh(a, b, c, d, x[i + 13], 4, 681279174);
		  d = md5_hh(d, a, b, c, x[i + 0], 11, -358537222);
		  c = md5_hh(c, d, a, b, x[i + 3], 16, -722521979);
		  b = md5_hh(b, c, d, a, x[i + 6], 23, 76029189);
		  a = md5_hh(a, b, c, d, x[i + 9], 4, -640364487);
		  d = md5_hh(d, a, b, c, x[i + 12], 11, -421815835);
		  c = md5_hh(c, d, a, b, x[i + 15], 16, 530742520);
		  b = md5_hh(b, c, d, a, x[i + 2], 23, -995338651);
		  a = md5_ii(a, b, c, d, x[i + 0], 6, -198630844);
		  d = md5_ii(d, a, b, c, x[i + 7], 10, 1126891415);
		  c = md5_ii(c, d, a, b, x[i + 14], 15, -1416354905);
		  b = md5_ii(b, c, d, a, x[i + 5], 21, -57434055);
		  a = md5_ii(a, b, c, d, x[i + 12], 6, 1700485571);
		  d = md5_ii(d, a, b, c, x[i + 3], 10, -1894986606);
		  c = md5_ii(c, d, a, b, x[i + 10], 15, -1051523);
		  b = md5_ii(b, c, d, a, x[i + 1], 21, -2054922799);
		  a = md5_ii(a, b, c, d, x[i + 8], 6, 1873313359);
		  d = md5_ii(d, a, b, c, x[i + 15], 10, -30611744);
		  c = md5_ii(c, d, a, b, x[i + 6], 15, -1560198380);
		  b = md5_ii(b, c, d, a, x[i + 13], 21, 1309151649);
		  a = md5_ii(a, b, c, d, x[i + 4], 6, -145523070);
		  d = md5_ii(d, a, b, c, x[i + 11], 10, -1120210379);
		  c = md5_ii(c, d, a, b, x[i + 2], 15, 718787259);
		  b = md5_ii(b, c, d, a, x[i + 9], 21, -343485551);
		  a = safe_add(a, f);
		  b = safe_add(b, g);
		  c = safe_add(c, h);
		  d = safe_add(d, j)
		}
		return Array(a, b, c, d)
	  }, md5_cmn = function (q, a, b, x, s, t) {
		return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s), b)
	  }, md5_ff = function (a, b, c, d, x, s, t) {
		return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t)
	  }, md5_gg = function (a, b, c, d, x, s, t) {
		return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t)
	  }, md5_hh = function (a, b, c, d, x, s, t) {
		return md5_cmn(b ^ c ^ d, a, b, x, s, t)
	  }, md5_ii = function (a, b, c, d, x, s, t) {
		return md5_cmn(c ^ (b | (~d)), a, b, x, s, t)
	  }, safe_add = function (x, y) {
		var a = (x & 0xFFFF) + (y & 0xFFFF);
		var b = (x >> 16) + (y >> 16) + (a >> 16);
		return (b << 16) | (a & 0xFFFF)
	  }, bit_rol = function (a, b) {
		return (a << b) | (a >>> (32 - b))
	  }, s2b = function (a) {
		var b = Array();
		var c = (1 << chrsz) - 1;
		for (var i = 0; i < a.length * chrsz; i += chrsz) b[i >> 5] |= (a.charCodeAt(i / chrsz) & c) << (i % 32);
		return b
	  }, b2h = function (a) {
		var b = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
		var c = "";
		for (var i = 0; i < a.length * 4; i++) {
		  c += b.charAt((a[i >> 2] >> ((i % 4) * 8 + 4)) & 0xF) + b.charAt((a[i >> 2] >> ((i % 4) * 8)) & 0xF)
		}
		return c;
	  };
	  return b2h(cmc5(s2b(str), str.length * chrsz))
	}

	/**
	 * Collects the User Agent information
	 * @return {string}
	 */
	function _userAgent() {
	  return window.navigator.userAgent || "Unknown User gent";
	};

	/**
	 * Collects the Display-String information
	 * @return {string}
	 */
	function _display() {
	  var screen = {};
	  for (var property in window.screen) {
		if (typeof window.screen[property] != 'function') {
		  screen[property] = window.screen[property];
		}
	  }
	  return JSON.stringify(screen);
	};

	/**
	 * Collects the Time Zone information
	 * @return {string}
	 */
	function _timezoneOffset() {
	  return _stringToMD5(new Date().getTimezoneOffset() + '');
	};

	/**
	 * Generates the fingerprint string with all the collected information
	 * @return {string}
	 */
	function _fingerprint() {
	  return _stringToMD5(_userAgent()) + '-' + _stringToMD5(_display()) + '-' + _stringToMD5(_timezoneOffset());
	}

	/**
	 * Gets the type of browser where the app is running
	 * @return {string}
	 */
	function _browserType() {
	  var ua = window.navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	  if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return 'IE';
	  }
	  if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR\/(\d+)/)
		if (tem != null) {
		  return 'Opera';
		}
	  }
	  return M[1];
	}


	/**
	 * Generates the facet with the browser info
	 * @return {string}
	 */
	function _browserFacet() {
	  return  {
		type: _browserType(),
		user_agent: window.navigator.userAgent.replace(';',','),
		platform: window.navigator.platform,
		language: window.navigator.languages || [window.navigator.language] || []
	  };
	}

	return {
	  fingerprint: _fingerprint,
	  browserFacet: _browserFacet
	};
  })(window, JSON);
