/**
 * Constants
 * @author Micaela Lopez <micaela.lopez@autodesk.com>
 * @type {exports}
 */
var Constants = (function () {

	var constants = {

	  // Session
	  SESSION_START: { type: 'S', scope: 'S'},
	  SESSION_END: { type: 'S', scope: 'E'},
	  SESSION_DETAILS: { type: 'S', scope: 'D'},
	  SESSION_FULL: { type: 'S', scope: 'F'},

	  // Project
	  PROJECT_START: { type: 'P', scope: 'S'},
	  PROJECT_END: { type: 'P', scope: 'E'},
	  PROJECT_FULL: { type: 'P', scope: 'F'},
	  PROJECT_NAME: { type: 'P', scope: 'N'},

	  // Document
	  DOCUMENT_START: { type: 'D', scope: 'S'},
	  DOCUMENT_END: { type: 'D', scope: 'E'},
	  DOCUMENT_FULL: { type: 'D', scope: 'F'},
	  DOCUMENT_NAME: { type: 'D', scope: 'N'},
	  DOCUMENT_TYPE: { type: 'D', scope: 'T'},

	  // Click
	  CLICK_PAGE: { type: 'C', scope: 'P'},
	  CLICK_COMPONENT: { type: 'C', scope: 'C'},
	  CLICK_DISPLAY: { type: 'C', scope: 'D'},
	  CLICK_OPERATION: { type: 'C', scope: 'O'},

	  // Metrics
	  METRICS_COMPONENT: { type: 'M', scope: 'C'},
	  METRICS_SESSION: { type: 'M', scope: 'S'},

	  // Gesture
	  GESTURE_COMPONENT: { type: 'G', scope: 'C'},

	  // Hover
	  HOVER_COMPONENT: { type: 'H', scope: 'C'},

	  // Background
	  BACKGROUND_CALL: { type: 'B', scope: 'C'},
	  BACKGROUND_UPDATE: { type: 'B', scope: 'U'},
	  BACKGROUND_DOWNLOAD: { type: 'B', scope: 'D'},
	  BACKGROUND_INSTALL: { type: 'B', scope: 'I'},
	  BACKGROUND_JOB: { type: 'B', scope: 'J'},

	  // View
	  USER_VIEW: { type: 'V', scope: 'U'},
	  APPLICATION_VIEW: { type: 'V', scope: 'A'},
	  OTHER_VIEW: { type: 'V', scope: 'O'}
	};

	function _getValues(eventType) {
	  var values = constants[eventType];
	  if (values) {
		return values;
	  } else {
		console.error('The event type: ' + eventType + ' does not exist.');
	  }
	}

	function _isValidType(type) {
	  if (type == 'S' || type == 'P' || type == 'D' || type == 'C' || type == 'M' || type == 'G' ||
		type == 'H' || type == 'B' || type == 'V')
		return true;
	  else
		return false;
	}

	function _isValidScope(scope) {
	  if (scope.length == 1) {
		return true;
	  } else {
		return false;
	  }
	}

	function _addEventType(eventName, type, scope) {
	  if (!_isValidScope(scope))
		console.error(type + ' is not a valid scope.');
	  else if (!_isValidType(type)) console.error(type + ' is not a valid type.');
	  else if (eventName == null || !(typeof eventName == "string" && eventName.length > 0))
		console.error(eventName + 'is not a valid event name.');
	  else {
		constants[eventName] = {
		  type: type,
		  scope: scope
		}
	  }
	}

	return{
	  /**
	   * Possible types of events
	   */
	  types: constants,
	  getValues: _getValues,
	  isValidType: _isValidType,
	  isValidScope: _isValidScope,
	  addEventType: _addEventType

	}

  })();

  module.exports = Constants;
