/**
 * ADP Analytics Javascript SDK Module
 * @author Micaela Lopez <micaela.lopez@autodesk.com>
 * @type {exports}
 */
var $ = require('../adp-analytics/jquery.min.js');
var Bacon = require('baconjs');
var Util = require('./Utils.js');
var Constants = require('./Constants.js');

if($.support) {
    $.support.cors = true;
}

/**
 * Constructor
 * @param facets required, object with registration facets
 * @param config optional, contains the configuration for the SDK
 * @param deviceId optional, unique device identifier
 * @returns {{login: _login, logout: _logout, setFacets: _setFacets, getFacets: _getFacets, trackEvent: _trackEvent, trackEventWithTypeAndScope: _trackEventWithTypeAndScope, addEventType: _addEventType}}
 * @constructor
 */
function Tracker(facets, config, deviceId) {

  // Ensuring that Product facet is present
  if (!facets || !facets.product || !facets.product.id)
    throw new Error('You need to specify the product facet with the product id to instantiate the tracker')

  // SDK Configuration
  var conf = Util.buildConf(config);

  if (!Util.isValidProduct(facets.product))
    _warn ('The product facet should be specified with the id, name & id_provider to instantiate the tracker');

  if (!Util.isValidProductIdProvider(facets.product.id_provider)) _warn("The provider id_provider is not a valid one");

  // SDK Context
  var context = {};
  context.device_id = deviceId;
  context.session_id = Util.genGuid();
  context.facets = Util.buildFacets(facets);

  // Add the user to the context
  if (facets.user) _login(facets.user);

  // Add Geo info to context
  if (!facets.geo && conf.enable_geo_data) _updateGeo();

  // Configure envents queue
  var eventsBus = new Bacon.Bus();
  $.support.cors = true;

  eventsBus.flatMap(function (data) {
    return Bacon.retry({
      source: function () {
        return Bacon.fromPromise(
          $.ajax({
            url: conf.server,
            type: 'post',
            dataType: 'json',
            data: JSON.stringify(data || {}),
            crossDomain: true,
			contentType: "application/json; charset=utf-8",
			beforeSend: function(xhr) {
				xhr.overrideMimeType("text/plain; charset=utf-8");
			}
          }));
      },
      retries: 3,
      isRetryable: function (response) {
        return response.status !== 200;
      },
      delay: function (context) {
        return context.retriesDone * 900;
      }
    })
  }).onValue(function (value) {
  });

  // Session messages
  if (conf.enable_session_messages) {
    eventsBus.push(Util.buildMsg(context, 'S', 'S', conf));
    var _onunload = window.onunload;
    window.onunload = function() {
      eventsBus.push(Util.buildMsg(context, 'S', 'E', conf));
      if (typeof _onunload === 'function')
        _onunload();
    };
    var _onbeforeunload = window.onbeforeunload;
    window.onbeforeunload = function() {
      eventsBus.push(Util.buildMsg(context, 'S', 'E', conf));
      if (typeof _onbeforeunload === 'function')
        return _onbeforeunload();
    };
  }

  function _updateGeo() {
    Bacon.fromCallback(navigator.geolocation.getCurrentPosition(function (it) {
      context.facets.geo = context.facets.geo || {};
      context.facets.geo.lat = it.coords.latitude;
      context.facets.geo.lon = it.coords.longitude;
    }, function (error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          _warn("User denied the request for geo-location");
          break;
        case error.POSITION_UNAVAILABLE:
          _warn("Location information is unavailable");
          break;
        case error.TIMEOUT:
          _warn("The request to get user location timed out");
          break;
        case error.UNKNOWN_ERR:
          _warn("An unknown error occurred while requesting geo-location");
          break;
      }
    }));
  }

  function _sendEvent(type, scope, facets, duration) {
    if (!facets.geo && conf.enable_geo_data) _updateGeo();
    context.duration = duration;
    eventsBus.push(Util.buildMsg(context, type, scope, conf, facets));
  }

  function _warn(msg) {
    if (!conf.suppress_warnings) {
      console.warn(msg);
    }
  }

  /**
   * Tracks an event, sends the corresponding information for tracking events to ASE services
   * @param eventType required, type of the event
   * @param facets optional, the facets used for API operation or product operations
   * @param duration optional, the facets used for API operation or product operations
   * @private
   */
  function _trackEvent(eventType, facets, duration) {
    var event = Constants.getValues(eventType);
    if (!event) console.error('A valid eventType needs to be specified to track an event');
    else {
      _sendEvent(event.type, event.scope, facets, duration);
    }
  };

  /**
   * Tracks an event, sends the corresponding information for tracking events to ASE services
   * @param type required, type of the event
   * @param scope required, type of the event
   * @param facets optional, the facets used for API operation or product operations
   * @param duration optional, the facets used for API operation or product operations
   * @private
   */
  function _trackEventWithTypeAndScope(type, scope, facets, duration) {
    if (!type) console.error('The type needs to be specified to track an event');
    else if (!scope) console.error('The Scope needs to be specified to track an event');
    else if (!Constants.isValidScope(scope)) console.error(scope + ' is not a valid scope');
    else if (!Constants.isValidType(type)) console.error(type + ' is not a valid type');
    else {
      _sendEvent(type, scope, facets, duration);
    }
  };

  /**
   * Adds an event type to the SDK
   * @param eventName
   * @param type
   * @param scope
   * @private
   */
  function _addEventType(eventName, type, scope) {
    Constants.addEventType(eventName, type, scope);
  }

  /**
   * Overrides the facets specified during the registration
   * @param facets
   * @private
   */
  function _setFacets(facets) {
    context.facets = Util.buildFacets(facets, context.facets);
  };

  /**
   * Returns the facets stored internally
   * @returns {*|context.facets}
   * @private
   */
  function _getFacets() {
    return context.facets;
  }

  /**
   * Adds the user information to the SDK
   * @param user required, the information of the user that logged in
   * @private
   */
  function _login(user) {
    if (!user) console.error('The information of the user that logged in needs to be specified');
    else if (!user.user_id) console.error('The user_id needs to be specified to log in a user');
    else if (!user.provider_name) console.error('The provider_name needs to be specified to log in a user');
    else {
      user.logged_in = true;
      context.facets.user_info[user.user_id + '_' + user.provider_name] = user;
      context.user_id = user.user_id;
      context.provider_name = user.provider_name;
    }
  };

  /**
   * Sets the specified user as currently logged out
   * @param userId
   * @private
   */
  function _logout(userId, providerName) {
    if (!userId) console.error('The user_id needs to be specified to log out a user');
    else if (!providerName) console.error('The provider_name needs to be specified to log out a user');
    else if (context.facets.user_info[userId + '_' + providerName]) {
      context.facets.user_info[userId + '_' + providerName].logged_in = false;
    } else {
      _warn("The user " + userId + ' / ' + providerName + " does not exist.");
    }
  };

  return{
    login: _login,
    logout: _logout,
    setFacets: _setFacets,
    getFacets: _getFacets,
    trackEvent: _trackEvent,
    trackEventWithTypeAndScope: _trackEventWithTypeAndScope,
    addEventType: _addEventType
  }
};

module.exports = function (facets, config, deviceId) {
  return new Tracker(facets, config, deviceId);
}

