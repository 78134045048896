/**
 * ADP Utils
 * @author Micaela Lopez <micaela.lopez@autodesk.com>
 * @type {exports}
 */
var DEFAULT_SERVER = "//ase.autodesk.com";
var SCHEMA_VERSION = "3.0.0";
var TRACK_ENDPOINT = "/adp/v1/analytics/upload";
var SDK_VERSION = "js_web-1.0.5";

var fp = require('./Fingerprint.js');

module.exports = (function () {

  /**
   * The current timestamp
   * @returns {string}
   * @private
   */
  function _timestamp() {
    var now = new Date(),
      tzo = -now.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        var norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return now.getFullYear()
      + '-' + pad(now.getMonth() + 1)
      + '-' + pad(now.getDate())
      + 'T' + pad(now.getHours())
      + ':' + pad(now.getMinutes())
      + ':' + pad(now.getSeconds())
      + '.' + now.getMilliseconds()
      + dif + pad(tzo / 60)
      + ':' + pad(tzo % 60);
  };

  function _buildConf(config) {
    var conf = {};
    if (config && 'undefined' !== typeof config.server)
      conf.server = config.server + TRACK_ENDPOINT;
    else
      conf.server = DEFAULT_SERVER + TRACK_ENDPOINT;
    if (config && 'undefined' !== typeof config.enable_geo_data)
      conf.enable_geo_data = config.enable_geo_data;
    else
      conf.enable_geo_data = true;
    if (config && 'undefined' !== typeof config.enable_browser_data)
      conf.enable_browser_data = config.enable_browser_data;
    else
      conf.enable_browser_data = true;
    if (config && 'undefined' !== typeof config.enable_session_messages)
      conf.enable_session_messages = config.enable_session_messages;
    else
      conf.enable_session_messages = true;
    if (config && 'undefined' !== typeof config.suppress_warnings)
      conf.suppress_warnings = config.suppress_warnings;
    else
      conf.suppress_warnings = false;
    return conf;
  }

  /**
   * Builds the facets
   * @param conf a map with the sdk configuration
   * @param fts the facets to set
   * @param ctxFts the facets that are already in place in the SDK context
   * @private
   */
  function _buildFacets(fts, ctxFts) {
    var facets = ctxFts || {};
    if (fts) {
      _facetsCoercion(fts);
      if (fts.browser) facets.browser = fts.browser || facets.browser;
      if (fts.geo) facets.geo = fts.geo || facets.geo;
      if (fts.product) facets.product = fts.product || facets.product;
      if (fts.device) facets.device = fts.device || facets.device;
      if (fts.operation) facets.operation = fts.operation || facets.operation;
      if (fts.entitlement) facets.entitlement = fts.entitlement || facets.entitlement;
      if (fts.component) facets.component = fts.component || facets.component;
      if (fts.content) facets.content = fts.content || facets.content;
      if (fts.http) facets.http = fts.http || facets.http;
      if (fts.experiment_info) facets.experiment_info = fts.experiment_info || facets.experiment_info;
      if (fts.search) facets.search = fts.search || facets.experiment_info;
      facets.user_info = facets.user_info || {};
    }
    return facets;
  }

  /**
   * Generates an GUID RFC-4122 compliant
   * @returns {string}
   * @private
   */
  function _genGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  /**
   * Fingerprint generation
   * @returns {String}
   * @private
   */
  function _createFingerprint() {
    return fp.fingerprint();
  };

  /**
   * Creates the message to be sent to ASE services
   * @param regInfo the id of the user that logged in
   * @param type required, type of the event
   * @param config the SDK configuration
   * @param trackFts operation facets
   * @returns {{}}
   * @private
   */
  function _buildMsg(regInfo, type, scope, config, trackFts) {
    var data = {};

    // API Settingse
    data.version = SCHEMA_VERSION;
    data.sdk_version = SDK_VERSION;
    data.status = 'OK';
    data.session_id = regInfo.session_id;
    data.device_id = regInfo.device_id || _createFingerprint();

    // App Settings
    data.user_id = regInfo.user_id;
    data.provider_name = regInfo.provider_name;

    data.device_time = _timestamp();
    data.duration = regInfo.duration || -1;
    data.event_id = _genGuid();
    data.type = type;
    data.scope = scope;

    data.cur_service_id = regInfo.cur_service_id;
    data.cur_service_name = regInfo.cur_service_name;

    data.module_name = 'ASE';
    data.method_name = '/upload';

    // Browser facets
    trackFts = trackFts || {};
    _facetsCoercion(trackFts);
    if (config.enable_browser_data) {
      trackFts.browser = trackFts.browser || fp.browserFacet() || {};
    }

    var facetsList = [];
    for (name in regInfo.facets) {
      if ('undefined' == typeof trackFts[name])
        trackFts[name] = regInfo.facets[name];
    }
    for (name in trackFts) {
      if (name === "user_info") {
        var users = [];
        for (prop in trackFts[name]) users.push(trackFts[name][prop]);
        if (users.length > 0) {
          data.user_info = users;
          facetsList.push("user_info");
        }
      } else {
        facetsList.push(name);
        for (prop in trackFts[name]) {
          data[name + '_' + prop] = trackFts[name][prop];
        }
      }
    }
    data.facets = facetsList;
    return data;
  };

  /**
   * Validates product facets
   * @param product
   * @returns {boolean} true if it's valid
   * @private
   */

  function _isValidProduct(product) {
    if (product) {
      if (!product.id || !product.name || !product.id_provider) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Validates the identity provider of the product
   * @param product
   * @returns {boolean} true if it's valid
   * @private
   */
  function _isValidProductIdProvider(provider) {
    var upper = String(provider).toLocaleUpperCase();
    if (upper == 'APPKEY' || upper == 'UPI1' || upper == 'UPI2')
      return true;
    else
      return false;
  }

 /**
  * Force the facet's map attributes to fulfill the desired field spec
  * @param fts facets
  * @private
  */
  function _facetsCoercion(fts) {
     if (fts) {
        if(fts.product) {
           fts.product.id_provider = _stringToLowerCase(fts.product.id_provider);
        }
        if (fts.operation) {
            _forceMapValuesToString(fts.operation.meta);
        }
        if (fts.entitlement) {
            _forceMapValuesToString(fts.entitlement.properties);
        }
        if (fts.component) {
            _forceMapValuesToString(fts.component.attributes);
            _forceMapValuesToString(fts.component.metrics);
        }
        if (fts.content) {
            _forceMapValuesToString(fts.content.meta);
        }
        if (fts.http) {
            _forceMapValuesToString(fts.http.form_params);
            _forceMapValuesToString(fts.http.request_headers);
            _forceMapValuesToString(fts.http.response_headers);
        }
     }
  }

 /**
  * Force map's values to be of String type
  * @param map
  * @private
  */
  function _forceMapValuesToString(map) {
     if(map){
        for (var key in map) {
           if(map[key]) map[key] = map[key] +'';
        }
     }
  }

 /**
  * Converts string characters to lower cases.
  * @param str
  * @private
  */
  function _stringToLowerCase(str) {
     if(str) {
         return String(str).toLocaleLowerCase()
     }
     return undefined;
  }

  return{
    buildConf: _buildConf,
    buildFacets: _buildFacets,
    buildMsg: _buildMsg,
    genGuid: _genGuid,
    isValidProduct: _isValidProduct,
    isValidProductIdProvider: _isValidProductIdProvider
  }
})
();
